import React from 'react';
import NavHeader from 'src/components/NavHeader/NavHeader'

function About() {
  return (
    <div className="About Body">
      <NavHeader />
      <h1>ABOUT</h1>
    </div>
  );
}

export default About;