import React from 'react';
import NavHeader from 'src/components/NavHeader/NavHeader'

function Contact() {
  return (
    <div className="Contact Body">
      <NavHeader />
      <h1>CONTACT</h1>
    </div>
  );
}

export default Contact;